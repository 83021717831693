<template>
  <div class="d-flex justify-center align-center py-12">
    <div class="text-center">
      <v-img
        v-if="!noImage"
        class="mb-n10 ma-auto"
        :width="width"
        :aspect-ratio="1"
        :src="require('@/assets/images/no-lists.png')"
      />

      <span class="display-1 grey--text">
        {{ details || 'No List' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoList',

  props: {
    details: {
      type: String,
    },

    noImage: {
      type: Boolean,
      default: false,
    },

    width: {
      type: [Number, String],
      default: 230,
    },
  },
}
</script>
