var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-challenges--lists-table"},[_c('v-simple-table',[(_vm.challenges.length)?_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Duration")]),_c('th',[_vm._v("Week Count")]),_c('th',[_vm._v("Plan")]),_c('th',[_c('v-select',{attrs:{"items":_vm.programRatings,"label":"Ratings"},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})],1),(_vm.listFor != 'Draft')?_c('th',[_vm._v("Archive")]):_vm._e()])]):_vm._e(),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll}},[(_vm.challenges)?_vm._l((_vm.challenges),function(challenge){return _c('tr',{key:challenge.id,staticClass:"clickable new-tab-link-row"},[_c('td',[_c('h2',{staticClass:"subtitle-1"},[_vm._v(_vm._s(challenge.name))]),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: _vm.redirectPathName,
                params: { id: challenge.id },
                meta: { stage: 1 },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(challenge.description)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: _vm.redirectPathName,
                params: { id: challenge.id },
                meta: { stage: 1 },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(challenge.startDate)+" - "+_vm._s(challenge.endDate)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: _vm.redirectPathName,
                params: { id: challenge.id },
                meta: { stage: 1 },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(challenge.week_count)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: _vm.redirectPathName,
                params: { id: challenge.id },
                meta: { stage: 1 },
              }}})],1),_c('td',[_vm._v(" "+_vm._s(challenge.package_name)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: _vm.redirectPathName,
                params: { id: challenge.id },
                meta: { stage: 1 },
              }}})],1),_c('td',[_c('div',{staticClass:"d-flex align-center rating"},[_c('span',{staticClass:"primary--text rating__label"},[_c('strong',[_vm._v(_vm._s(challenge[_vm.ratingVal] || 0))])]),_c('v-rating',{attrs:{"value":challenge[_vm.ratingVal],"half-increments":"","readonly":"","background-color":"primary lighten-3","color":"primary","large":""}}),_c('span',{staticClass:"grey--text text-caption"},[_vm._v("("+_vm._s(challenge[(_vm.ratingVal + "_count")])+")")])],1)]),(_vm.listFor != 'Draft')?_c('td',[(_vm.listFor != 'Archived')?_c('v-btn',{staticClass:"bg-primary-gradient primary ml-auto",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.archive(challenge, true)}}},[_vm._v("Archive")]):_vm._e(),(_vm.listFor == 'Archived')?_c('v-btn',{staticClass:"bg-primary-gradient primary ml-auto",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.archive(challenge, false)}}},[_vm._v("Unarchive")]):_vm._e()],1):_vm._e()])}):_vm._e()],2),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"5","row":""}}):_vm._e()],1),(!_vm.challenges.length && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Challenges"}}):_vm._e(),_c('confirm-dialog',{ref:"confirmArchiving",on:{"close":_vm.confirmed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }