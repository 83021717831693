<template>
  <tbody class="table-skeleton">
    <tr
      class="table-skeleton-loader"
      v-bind="$attrs"
      v-for="(count, rowIndex) in rowCount"
      :key="rowIndex"
    >
      <td v-for="(count, colIndex) in columnCount" class="pl-0" :key="colIndex">
        <v-skeleton-loader type="list-item" />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'TableSkeletonLoader',

  props: {
    row: {
      type: [Number, String],
      required: true,
      default: () => 1,
    },

    column: {
      type: [Number, String],
      required: true,
      default: () => 1,
    },
  },

  computed: {
    rowCount() {
      return this.row ? this.row instanceof Number || Number(this.row) : 1
    },

    columnCount() {
      return this.column
        ? this.column instanceof Number || Number(this.column)
        : 1
    },
  },
}
</script>
