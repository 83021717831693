import Vue from 'vue'

const eventBus = new Vue()

export default eventBus

export const Events = {
  toggleSidebar() {
    eventBus.$emit('TOGGLE_SIDEBAR')
  },
}
