<template>
  <div class="bbr-challenges--lists-table">
    <v-simple-table>
      <thead v-if="challenges.length">
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Duration</th>
          <th>Week Count</th>
          <th>Plan</th>
          <th>
            <v-select
              :items="programRatings"
              v-model="rating"
              label="Ratings"
            ></v-select>
          </th>
          <th v-if="listFor != 'Draft'">Archive</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <template v-if="challenges">
          <tr
            v-for="challenge in challenges"
            :key="challenge.id"
            class="clickable new-tab-link-row"
          >
            <td>
              <h2 class="subtitle-1">{{ challenge.name }}</h2>
              <router-link
                :to="{
                  name: redirectPathName,
                  params: { id: challenge.id },
                  meta: { stage: 1 },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ challenge.description }}
              <router-link
                :to="{
                  name: redirectPathName,
                  params: { id: challenge.id },
                  meta: { stage: 1 },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ challenge.startDate }} - {{ challenge.endDate }}
              <router-link
                :to="{
                  name: redirectPathName,
                  params: { id: challenge.id },
                  meta: { stage: 1 },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ challenge.week_count }}
              <router-link
                :to="{
                  name: redirectPathName,
                  params: { id: challenge.id },
                  meta: { stage: 1 },
                }"
                class="row-link"
              />
            </td>
            <td>
              {{ challenge.package_name }}
              <router-link
                :to="{
                  name: redirectPathName,
                  params: { id: challenge.id },
                  meta: { stage: 1 },
                }"
                class="row-link"
              />
            </td>
            <td>
              <div class="d-flex align-center rating">
                <span class="primary--text rating__label">
                  <strong>{{ challenge[ratingVal] || 0 }}</strong>
                </span>
                <v-rating
                  :value="challenge[ratingVal]"
                  half-increments
                  readonly
                  background-color="primary lighten-3"
                  color="primary"
                  large
                ></v-rating>
                <span class="grey--text text-caption"
                  >({{ challenge[`${ratingVal}_count`] }})</span
                >
              </div>
            </td>
            <td v-if="listFor != 'Draft'">
              <v-btn
                v-if="listFor != 'Archived'"
                :disabled="loading"
                class="bg-primary-gradient primary ml-auto"
                @click="archive(challenge, true)"
                >Archive</v-btn
              >
              <v-btn
                v-if="listFor == 'Archived'"
                :disabled="loading"
                class="bg-primary-gradient primary ml-auto"
                @click="archive(challenge, false)"
                >Unarchive</v-btn
              >
            </td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list
      v-if="!challenges.length && !loading"
      details="No Available Challenges"
    />
    <confirm-dialog ref="confirmArchiving" @close="confirmed" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapMutations } from 'vuex'
import Event from '@/services/eventBus'

import { mdiDotsVertical } from '@mdi/js'
import { includes } from 'lodash'

export default {
  name: 'ChallengesListTable',

  directives: {
    infiniteScroll,
  },

  components: {
    TableSkeletonLoader,
    NoList,
    ConfirmDialog,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    challenges: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    listFor: {
      type: String,
      required: true,
    },
    isSearching: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
      programRatings: [
        { text: 'Overall Experience', value: 1 },
        { text: 'Nutrition', value: 2 },
        { text: 'Training', value: 3 },
      ],
      rating: 1,
    }
  },

  computed: {
    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },

    redirectPathName() {
      return includes(this.$route.name, 'current')
        ? 'challenge.details'
        : 'new.challenge.details'
    },

    ratingVal() {
      let col
      // 1 - Overall Exp, 2 - Nutrition, 3 - Training
      switch (this.rating) {
        case 2:
          col = 'nutrition_rating'
          break
        case 3:
          col = 'training_rating'
          break
        default:
          col = 'overall_exp_rating'
      }

      return col
    },
  },

  methods: {
    ...mapActions({
      archiveChallenge: 'challenges/archiveChallenge',
    }),

    ...mapMutations({
      updateArchivedList: 'challenges/shiftChallengeOnArchiveToTargetList',
    }),
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    goTo(challenge) {
      this.$emit('goTo', challenge.id)
    },
    async archive(challenge, setArchive) {
      const confirm = await this.$refs.confirmArchiving.open(
        `${setArchive ? 'Archive' : 'Unarchive'} Challenge`,
        `Are you sure you want to ${
          setArchive ? 'archive' : 'unarchive'
        } this challenge?`
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.archiveChallenge({ id: challenge.id, setArchive })
        await this.updateArchivedList({ challenge, setArchive })

        setTimeout(() => {
          this.loading = false

          if (setArchive) {
            Event.$emit('update-current-challenge-list')
          } else {
            Event.$emit('udpate-archived-challenge-list')
          }
        }, 900)
      } catch (err) {
        this.loading = false
        this.$refs.confirmArchiving.open(
          'Error',
          'There was an error archiving the selected event'
        )
      }
    },
  },
}
</script>
