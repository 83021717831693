<template>
  <v-row class="bbr-challenges--archived">
    <v-col v-if="hasChallenges || isSearching" cols="12">
      <div class="form-container d-flex align-center">
        <div class="search-form">
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            label="Search program"
            style="width:400px"
            v-model="searchKey"
            @input="searchChange"
          />
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <challenges-list-table
        :is-searching="isSearching"
        :challenges="challenges"
        :loading="loading"
        :meta="listMeta"
        list-for="Archived"
        @goTo="goTo"
        @loadMore="loadMoreChallenges"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChallengesListTable from '../components/ChallengesListTable'
import { mapState, mapActions, mapMutations } from 'vuex'
import Event from '@/services/eventBus'
import debounce from 'lodash/debounce'

export default {
  name: 'ArchivedProgramLists',

  data() {
    return {
      loading: false,
      searchKey: null,
    }
  },

  components: {
    ChallengesListTable,
  },

  computed: {
    ...mapState({
      filter: (state) => state.challenges.filter,
      challenges: (state) => state.challenges.archived.list,
      listMeta: (state) => state.challenges.archived.listMeta,
    }),

    hasChallenges() {
      return !!this.challenges.length
    },

    isSearching() {
      return !!this.searchKey
    },
  },

  created() {
    this.setFilter({ search: null, completed: true, archived: true })

    if (!this.filter.completed) {
      this.searchKey = this.filter.search
    }

    if (this.searchKey) {
      this.fetchChallenges()
    }

    Event.$on('udpate-archived-challenge-list', () => {
      this.setFilter({ completed: true, archived: true })
      this.clearList()
      this.fetchChallenges()
    })
  },
  methods: {
    ...mapActions({
      getChallenges: 'challenges/getChallenges',
    }),

    ...mapMutations({
      setFilter: 'challenges/setFilter',
      clearList: 'challenges/clearList',
    }),

    searchChange(key) {
      this.setFilter({ search: key, completed: true, archived: true })

      this.searchChallenge()
    },

    searchChallenge: debounce(function() {
      this.clearList()
      this.fetchChallenges()
    }, 550),

    async fetchChallenges(page = 1) {
      if (this.loading) return

      this.loading = true

      let search = this.searchKey
      let completed = 1
      let archived = 1
      let params = { page, search, archived, completed }

      await this.getChallenges(params)

      this.loading = false
    },

    loadMoreChallenges() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchChallenges(this.listMeta.current_page + 1)
      }
    },

    goTo(programId) {
      this.$router.push({
        name: 'new.challenge.details',
        params: { id: programId },
      })
    },
  },
}
</script>
